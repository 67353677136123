.LookPreview img {
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  object-fit: contain;
  /* opacity: 0; */
  position: absolute;
  right: 0;
  top: 0;
  /* transition: opacity 0.3s ease; */
  width: 600px;
}
.LookPreview img.placeholder {
  position: relative;
}
.LookPreview img.loaded {
  opacity: 1;
}
.LookPreview img[class*='shadow-'] {
  transition-duration: 0.6s;
  transition-delay: 0.2s;
  opacity: 0;
}

.LookPreview img.hanger-hanger {
  z-index: 0;
}
.LookPreview img.builder-image-shoes {
  z-index: 10;
}
.LookPreview img.builder-image-belt {
  z-index: 20;
}
.LookPreview img.builder-image-cufflinks {
  z-index: 30;
}
.LookPreview img.builder-image-pants {
  z-index: 40;
}
.LookPreview img.builder-image-socks {
  z-index: 50;
}
.LookPreview img.shadow-socks_shadows {
  z-index: 49;
}
.LookPreview img.builder-image-vest_full {
  z-index: 70;
}
.LookPreview img.builder-image-jacket_full {
  z-index: 80;
}
.LookPreview img.builder-image-shirt_full {
  z-index: 90;
}
.LookPreview img.builder-image-shirt_tail {
  z-index: 89;
}
.LookPreview img.builder-image-cummerbund {
  z-index: 100;
}
.LookPreview img.shadow-cummerbund_shadows {
  z-index: 99;
}

.LookPreview img.builder-image-tie_full {
  z-index: 110;
}
.LookPreview img.shadow-tie_full_shadows {
  z-index: 109;
}

.LookPreview img.shadow-shirt_point_shadows,
.LookPreview img.shadow-shirt_spread_shadows,
.LookPreview img.shadow-shirt_wingtip_shadows {
  z-index: 120;
}

.LookPreview img.builder-image-vest_front {
  z-index: 130;
}
.LookPreview img.shadow-vest_shadows {
  z-index: 129;
}

.LookPreview img.shadow-jacket_shadows {
  z-index: 140;
}
.LookPreview img.builder-image-jacket_front {
  z-index: 150;
}
.LookPreview img.builder-image-shirt_collar {
  z-index: 160;
}

.LookPreview img.builder-image-tie_no_strap {
  z-index: 170;
}
.LookPreview img.shadow-tie_no_strap_shadows {
  z-index: 169;
}

.LookPreview img.builder-image-pocket_square {
  z-index: 180;
}
.LookPreview img.shadow-pocket_square_shadows {
  z-index: 179;
}

.LookPreview img.builder-image-tie_bar {
  z-index: 190;
}

.LookPreview img.builder-image-lapel_pin {
  z-index: 200;
}
.LookPreview img.shadow-lapel_pin_shadows {
  z-index: 199;
}

.LookPreview img.builder-image-lapel_pin,
.LookPreview img.builder-image-pocket_square {
  opacity: 0;
}
 
.LookPreview.jacket img.builder-image-pocket_square.loaded,
.LookPreview.jacket img.builder-image-lapel_pin.loaded {
  opacity: 1;
}

.LookPreview.vest img.builder-image-shirt_tail,
.LookPreview.cummerbund img.builder-image-shirt_tail {
  opacity: 0;
}

.LookPreview.vest .builder-image-jacket_front.loaded ~ img.shadow-jacket_shadows.loaded,
.LookPreview.shirt .builder-image-jacket_front.loaded ~ img.shadow-jacket_shadows.loaded {
  opacity: 0.6;
}

.LookPreview.pants .builder-image-socks.loaded ~ img.shadow-socks_shadows.loaded {
  opacity: 0.5;
  transform: translateY(1%);
}

.builder-image-shirt_collar.loaded ~ img.shadow-shirt_point_shadows.loaded,
.builder-image-shirt_collar.loaded ~ img.shadow-shirt_spread_shadows.loaded,
.builder-image-shirt_collar.loaded ~ img.shadow-shirt_wingtip_shadows.loaded {
  opacity: 0.5;
}


.LookPreview.shirt .builder-image-tie_full.loaded ~ img.shadow-tie_full_shadows.loaded {
  opacity: 0.4;
}
.LookPreview.shirt .builder-image-tie_no_strap.loaded ~ img.shadow-tie_no_strap_shadows.loaded {
  opacity: 0.8;
}

.LookPreview.shirt .builder-image-vest_front.loaded ~ img.shadow-vest_shadows.loaded,
.LookPreview.tie .builder-image-vest_front.loaded ~ img.shadow-vest_shadows.loaded {
  opacity: 1;
}

.LookPreview.shirt .builder-image-cummerbund.loaded ~ img.shadow-cummerbund_shadows.loaded {
  opacity: 1;
}

.LookPreview.jacket .builder-image-lapel_pin.loaded ~ img.shadow-lapel_pin_shadows.loaded {
  opacity: 0.5;
  transform: translateY(0.1%);
}