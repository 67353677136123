@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'DomD Reg';
    font-display: swap;
    src: local('DomaineDisplay-Regular'), local('Domaine Display'),
        url('/fonts/DomDWeb-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/DomDWeb-Regular.woff2') format('woff2'),
        url('/fonts/DomDWeb-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Roboto Condensed Bold';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
        url('/fonts/roboto-condensed-v27-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/roboto-condensed-v27-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* roboto-condensed-400 - latin */
  @font-face {
    font-family: 'Roboto Condensed Light';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Condensed'), local('RobotoCondensed')
        url('/fonts/roboto-condensed-v27-latin-400.woff2') format('woff2'),
        url('/fonts/roboto-condensed-v27-latin-400.woff') format('woff');
  }

  /* Default Type styles */

  html {
    @apply text-gray-dark antialiased;
  }

  ul, ol {
    @apply ml-32 list-outside;

    li {
      @apply mb-8;
    }
  }

  ol li {
    @apply list-decimal;
  }

  ul:not([class*="list-none"]) li { 
    @apply list-none relative;

    &:before {
      content: url('data:image/gif;base64,R0lGODlhEAAKAIABAMzMzP///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOCAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0QkExQ0E4MTRCQzQxMUU4OTRDQUFDMDhCRjRBRUI5QyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0QkExQ0E4MjRCQzQxMUU4OTRDQUFDMDhCRjRBRUI5QyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkJDN0Y4QzJBNEJCRTExRTg5NENBQUMwOEJGNEFFQjlDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRCQTFDQTgwNEJDNDExRTg5NENBQUMwOEJGNEFFQjlDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEAQAAAQAsAAAAABAACgAAAg6Mj6nL7Q2inBS8i7PWBQA7');
      position: absolute;
      @apply -ml-32;
    }
  }
  
  p > a,
  li > a,
  .text-anchor {
    @apply text-gold-darker hover:underline;
  }

  /* 
    following tailwind typography convention
    .prose is added to wrapper of content where you can't add classes directly
  */

  .prose {
    @apply space-y-16;

    figure {
      @apply mx-0;
    }

    table {
      @apply w-full border-collapse border-1 border-gray;
  
      th {
        @apply p-8;
      }
      
      tr:nth-of-type(odd) {
        @apply bg-gray-lighter;  
      }
      
      td {
        @apply py-8 px-16 border-1 border-gray;
      }
    }
  }

  /* forms */
  .form-stepper::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: block;
    @apply w-16;
    @apply h-32;
    background-image: url('https://gentux.imgix.net/1614858535_iconTriangleUpDown.png');
    background-size: 7px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    transform: translate(4px, 0);
  }
  
  input[type='file']::-webkit-file-upload-button {
    width: 0;
    padding: 0;
    border: none;
    overflow: hidden;
  }

  .container .container {
    padding-left: 0;
    padding-right: 0;
  }

  .row {
    @apply grid grid-cols-12 gap-x-16 sm:gap-x-32;
  }

  .m-section {
    @apply my-64 md:my-128;
  }
  .p-section {
    @apply py-64 md:py-128;
  }
  .m-section-hero {
    @apply mt-32 mb-64 xs:my-64 md:my-128;
  }
  .p-section-hero {
    @apply pt-32 pb-64 xs:py-64 md:py-128;
  }

  /* For Accessibility */
  .sr-only { 
    position: absolute; 
    left: -10000px; 
  } 
  .skip-link { 
    color: black;
    background-color: white; 
    padding: 12px; 
    border: 2px solid black; 
    border-radius: 5px; 
    z-index: 999; 
  } 
  .skip-link:focus-visible { 
    left: 0px; 
  }
}

@layer components {
  .polaroid {
    @apply border-white border-8 xs:border-16 shadow-3;
  }

  .btn {
    @apply inline-flex items-center justify-center gap-x-4 cursor-pointer;
    @apply py-16 px-24;
    @apply border border-transparent;
    @apply whitespace-nowrap text-center no-underline;
    @apply transition-all duration-150;
    @apply hover:bg-white hover:text-black hover:shadow-3;
    @apply disabled:cursor-not-allowed disabled:opacity-20 disabled:shadow-none;
  }

  .btn-sm {
    @apply py-8 px-16;
  }

  .btn-default {
    @apply border-b-gray bg-gray text-black;
    @apply disabled:bg-gray disabled:text-black;
  }
  .btn-info {
    @apply border-b-gold bg-gold text-black;
    @apply disabled:bg-gold disabled:text-black;
  }
  .btn-primary {
    @apply border-b-black bg-black text-white;
    @apply disabled:bg-black disabled:text-white;
  }
  .btn-danger {
    @apply border-b-red bg-red text-white;
    @apply disabled:bg-red disabled:text-white;
  }
  .btn-success {
    @apply border-b-green bg-green text-white;
    @apply disabled:bg-green disabled:text-white;
  }
  
  .btn-default-outline {
    @apply border border-gray text-black;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-gray disabled:text-black;
  }
  .btn-info-outline {
    @apply border border-gold text-gold-darker;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-gold disabled:text-gold;
  }
  .btn-primary-outline {
    @apply border border-black text-black;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-black disabled:text-black;
  }
  .btn-danger-outline {
    @apply border border-red text-red;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-red disabled:text-red;
  }
  .btn-success-outline {
    @apply border border-green text-green;
    @apply hover:border-t-transparent hover:border-l-transparent hover:border-r-transparent;
    @apply disabled:border-green disabled:text-green;
  }

  a.disabled {
    @apply cursor-not-allowed opacity-20 shadow-none pointer-events-none;
  }

  .ColorOfTheYear {
    .GentuxEm {
      @apply text-gold-dark;
    }
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }

  .sales-callout-alternate-button > a {
    width: 100%;
  }

  .discounted-price {
    @apply text-red-light;
    @apply font-bold;
  }

  .promo-code {
    @apply font-bold;
  }

  .product-detail-page {
    .base-price--discounted {
      @apply font-bold;
      @apply text-gray-dark;
    }

    .promo-code {
      @apply font-condensed;
      @apply font-bold;
    }
  }

  @media (max-width: calc(theme('screens.xs') - 1px)) {
    .product-index-page.container {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@layer utilities {
  .hyphens {
    hyphens: auto;
  }

  .max-h-none {
    max-height: none;
  }

  .max-w-none {
    max-width: none;
  }

  @responsive {
    .scrolling-touch {
      -webkit-overflow-scrolling: touch;
    }
    .scrolling-auto {
      -webkit-overflow-scrolling: auto;
    }
    .scrollbar-none::-webkit-scrollbar {
      display: none;
    }
  }
}
